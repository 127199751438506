import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import "./contact.css"
import AOS from "aos";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Close } from '@mui/icons-material';
import { Alert, IconButton } from '@mui/material';


const Contact = () => {

    const [success, setsuccess] = useState(false);

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const onSubmit = data => {
        axios
            .post("/contacts", data)
            .then((response) => {
                setsuccess(true);
                reset()
            });
    };

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <section className="contact-area" id="contact">
            <div className="container">
                <div data-aos="zoom-in-up" className="upper-content text-center" >
                    <h2>যোগাযোগ </h2>
                    <p>যে কোনো ধরনের প্রয়োজনে আমাদের সাথে যোগাযোগ করতে  পারো।   তোমার কোনো  মতামত বা  জানার  থাকলে  ফর্ম এ নাম ঠিকানা এবং মেসেজ  পাঠাতে  পারো। </p>
                </div>
                <div className="contact-main">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1" data-aos="zoom-out-right">
                            <div className="contact-left pe-xl-4">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3615.5249291138543!2d90.01458311484684!3d25.016253845156953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3757d6137a96d3dd%3A0xa4ac12dd3a812def!2sMuslim%20Market!5e0!3m2!1sen!2sbd!4v1637434943131!5m2!1sen!2sbd"
                                    allowFullScreen=""></iframe>
                                <ul>
                                    <li className="addres"><i className="fas fa-map-marker-alt"></i> মুসলিম মার্কেট (৩য় তলা ), সাউথইস্ট ব্যাংকের উপরে,  <br /> শহীদ বুলবুল সড়ক, শেরপুর টাউন, শেরপুর।
                                    </li>
                                    <li><i className="far fa-envelope"></i> <a
                                        href="mailto:ait.sherpur@gmail.com">ait.sherpur@gmail.com</a></li>
                                    <li><i className="fas fa-phone-alt"></i> <a href="tel: 01718067882">+8801718067882</a></li>
                                    <li><i className="fas fa-globe"></i> <NavLink to="/">www.ait.edu.bd</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-out-left">

                            {success && <Alert
                                variant="filled" severity="success"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setsuccess(false);
                                        }}
                                    >
                                        <Close fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                You are successfully Contact
                            </Alert>}

                            <div className="contact-right">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <input type="text" placeholder="Name" {...register("name", { required: true })} />
                                    {errors.name && <p className="text-danger">This Phone Number  is required</p>}


                                    <input type="email" placeholder="Email" {...register("email", { required: true, pattern: /^\S+@\S+\.\S+$/ })} />
                                    {errors.email && <p className="text-danger">This Email  is invalid</p>}


                                    <input type="text" placeholder="Phone" {...register("phone", { required: true, pattern: /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/ })} />
                                    {errors.phone && <p className="text-danger">This Phone  is invalid</p>}


                                    <textarea name="" id="" cols="30" rows="5" placeholder="Message" {...register("description", { required: true })}></textarea>
                                    {errors.description && <p className="text-danger  text-bold">This Description Field is invalid</p>}

                                    <button type="submit"> Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;