import React, { useEffect, useState } from 'react';
import { Card, Container, ListGroup, ListGroupItem } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Paper, TableRow, TableHead, TableContainer, TableBody, Table, Button, Modal, Fade, Typography, Box, Backdrop } from '@mui/material';
import { ConnectWithoutContact, Preview } from '@mui/icons-material';
import "./allUser.scss"
import axios from "axios";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AllContacts = () => {

    const [contacts, setcontacts] = useState([])
    const [contact, setcontact] = useState({})
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setcontact({})
        setOpen(false);

    }


    const handleOpen = (id) => {
        const finduser = contacts.find(contact => contact._id === id);
        setOpen(true);
        setcontact(finduser)
    }

    useEffect(() => {
        const fetchPosts = async () => {
            const res = await axios.get("/contacts");
            setcontacts(res.data);
        };
        fetchPosts();
    }, [contacts]);

    console.log(contact)

    return (

        <>
            <div>
                <Container>
                    <div className="table-header text-center">
                        <h3>All User Contacts</h3>
                    </div>
                    <div className="table-body">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Name</StyledTableCell>
                                        <StyledTableCell align="center">Email Address</StyledTableCell>
                                        <StyledTableCell align="center">Phone</StyledTableCell>
                                        <StyledTableCell align="center">Date</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contacts.map(contact => (
                                        <StyledTableRow key={contact._id}>
                                            <StyledTableCell align="center"> {contact.name} </StyledTableCell>
                                            <StyledTableCell align="center">{contact.email}</StyledTableCell>
                                            <StyledTableCell align="center">{contact.phone}</StyledTableCell>
                                            <StyledTableCell align="center"> {new Date(contact.createdAt).toLocaleDateString()} {new Date(contact.createdAt).toLocaleTimeString()}</StyledTableCell>
                                            <StyledTableCell align="center"><Button variant="contained" onClick={() => handleOpen(contact._id)}><Preview /></Button></StyledTableCell>
                                        </StyledTableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Container>
            </div>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Card>
                            <Card.Header className="text-center"><h3>User Info....</h3></Card.Header>
                            <Card.Body>
                                <ListGroup className="list-group">
                                    <ListGroupItem>Name : {contact.name}</ListGroupItem><ListGroupItem>Phone : {contact.phone}</ListGroupItem><ListGroupItem>Name : {contact.email}</ListGroupItem>
                                </ListGroup>
                                <br />
                                <Card.Text>
                                    Message: <br />
                                    {contact.description}
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Box>
                </Fade>
            </Modal>

        </>
    );
};

export default AllContacts;