import React from 'react';

const AddUser = () => {
    return (
        <div>
            <h2>Add User </h2>
        </div>
    );
};

export default AddUser;