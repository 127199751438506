import React, { useState, useEffect } from 'react';
import aboutImg from "../../images/support.png"

import "./abouts.css"
import AOS from "aos";

const Abouts = () => {

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <section className="about-area" id="about">
            <div className="container">
                <div data-aos="zoom-in-up" className="upper-content text-center">
                    <h2>আমাদের সম্পর্কে </h2>

                </div>
                <div className="about-main">
                    <div className="row">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="about-left">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="about-item" data-aos="fade-up">
                                            <img src="https://monirwd.github.io/single_page/images/about-1.png" alt="" />
                                            <h4>প্র্যাক্টিক্যাল ল্যাব ক্লাস </h4>
                                            <p>আমাদের রয়েছে সুসমৃদ্ধ আধুনিক কম্পিউটার ল্যাব।

                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6" data-aos="fade-up"
                                    >
                                        <div className="about-item">
                                            <img src="https://monirwd.github.io/single_page/images/about-2.png" alt="" />
                                            <h4>রিভিউ  ক্লাস </h4>
                                            <p>পিছিয়ে পরা শিক্ষার্থীদের জন্য রয়েছে  রিভিউ ক্লাসের ব্যবস্থা </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-item" data-aos="fade-up"
                                        >
                                            <img src="https://monirwd.github.io/single_page/images/about-3.png" alt="" />
                                            <h4>ক্লাস  ভিডিও</h4>
                                            <p>প্রতিদিনের  রেকর্ডেড ক্লাস গুলো প্রাইভেট ফেসবুক গ্রুপে  আপলোড করে দেয়া হবে </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-item" data-aos="fade-up"
                                        >
                                            <img src="https://monirwd.github.io/single_page/images/about-4.png" alt="" />
                                            <h4>24/7 অনলাইন সাপোর্ট </h4>
                                            <p>যেকোনো   সমস্যায়  আমাদের সাথে  যোগাযোগ দ্রুততম সময়ে  সমাধান করে নেওয়া  যাবে </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="about-right" data-aos="fade-left">
                                <img src={aboutImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Abouts;