import React, { useState, useEffect } from 'react';
import "./courses.css"
import AOS from "aos";
import { NavLink } from 'react-router-dom';
const Courses = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });

    return (
        <section className="course-area" id="courses">
            <div className="container">
                <div data-aos="zoom-in-up" className="upper-content text-center">
                    <h2>কোর্স সমূহ </h2>
                    <p>প্র্যাকটিক্যাল প্রজেক্ট করে কোডিং দক্ষতা অর্জন করো আমাদের ওয়েব ডিজাইন ও ডেভেলপমেন্ট কোর্স ও ট্রেনিংয়ের সাহায্যে।</p>

                </div>
                <div className="course-main">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div data-aos="zoom-in-right" className="course-item" >
                                <img src=" https://i.ibb.co/NS8fctP/office-application.png" alt="Image not found " />
                                <figcaption className="course-cnt">
                                    <NavLink to="/computer-office-application"><h4>কম্পিউটার অফিস অ্যাপ্লিকেশন</h4> </NavLink>
                                    <p>বাংলাদেশ কারিগরি শিক্ষা বোর্ড কর্তৃক অনুমোদিত Advanced IT Institute (প্রতিষ্ঠান কোড: ৫৬০৭৩) ২০১২ সাল থেকে শেরপুরে দক্ষ মানব সম্পদ গড়তে কম্পিউটার অফিস অ্যাপ্লিকেশন প্রশিক্ষণ দিয়ে যাচ্ছে। তুমিও হতে পারো দৈনন্দিন এবং অফিসিয়াল কাজে পারদর্শী।</p>
                                    <NavLink className="link" to="/computer-office-application">Learn More <i
                                        className="fas fa-chevron-right"></i></NavLink>
                                </figcaption>
                            </div>
                        </div>
                        <div data-aos="zoom-in-left" className="col-lg-4 col-md-6">
                            <div className="course-item">
                                <img src=" https://i.ibb.co/6sZvbyC/ict.jpg" alt="Image not found " />
                                <figcaption className="course-cnt">
                                    <NavLink to="/hsc-ict"><h4>HSC ICT with Sanjoy Sir</h4> </NavLink>
                                    <p>HSC  বোর্ড পরীক্ষা এবং বিশ্ববিদ্যালয়ের ভর্তি  পরীক্ষায় সর্বোচ্চ ভালো করতে  অভিজ্ঞ সঞ্জয় স্যার রয়েছে  তোমাদের পাশে।  ভালো  ফলাফলের  জন্য  নিবিড় অনুশীলন, নিয়মিত পরীক্ষা ও সলভ ক্লাস গ্রহণ করা হয়।  </p>
                                    <NavLink className="link" to="/hsc-ict">Learn More <i
                                        className="fas fa-chevron-right"></i></NavLink>
                                </figcaption>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div data-aos="zoom-in-up" className="course-item">
                                <img src="https://i.ibb.co/k9CdPjD/web-design.jpg" alt="Image not found " />
                                <figcaption className="course-cnt">
                                    <NavLink to="/web-deign"><h4>ওয়েব ডিজাইন </h4> </NavLink>
                                    <p>বাংলাদেশে IT Industry এর উন্নতির সাথে সাথেই বেড়েছে Web Designer এর প্রয়োজনীয়তা। নিজের অথবা অন্যের অনলাইন প্ল্যাটফর্ম তৈরি করাই ওয়েব ডিজাইনের মূল কাজ। ওয়েব ডিজাইনে ক্যারিয়ার গড়তে চাইলে প্রথমেই জানতে হবে Web Design এর ভাষা। </p>
                                    <NavLink className="link" to="/web-deign">Learn More <i
                                        className="fas fa-chevron-right"></i></NavLink>
                                </figcaption>
                            </div>
                        </div>
                        <div data-aos="zoom-in-left" className="col-lg-4 col-md-6">
                            <div className="course-item">
                                <img src=" https://i.ibb.co/yq77V0F/web-development.jpg" alt="Image not found " />
                                <figcaption className="course-cnt">
                                    <NavLink to="/web-development"><h4> ওয়েব ডেভেলপমেন্ট</h4></NavLink>
                                    <p>একদম জিরো থেকে, কোন প্রকার পূর্ব অভিজ্ঞতা ছাড়াই একজন প্রফেশনাল ওয়েব ডেভেলপার হওয়ার জন্য যা যা লাগবে তার সবকিছুই সহজ করে দেখানো   হবে  এই কোর্সে।

                                        ফ্রন্টএন্ড (JavaScript, ES6, bootstrap, React), ব্যাকএন্ড (node, express, API), ডাটাবেজ (mongodb), ক্রোম ডেভ টুল, হোস্টিং, গিটহাব, ইন্টারভিউ প্রবলেম সলভিং - সবকিছু!


                                    </p>
                                    <NavLink className="link" to="/web-development">Learn More <i
                                        className="fas fa-chevron-right"></i></NavLink>
                                </figcaption>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Courses;