import React, { useEffect } from 'react';
import Abouts from '../../components/abouts/Abouts';
import Contact from '../../components/contact/Contact';
import Courses from '../../components/courses/Courses';
import Footer from '../../components/footer/Footer';
import Gallary from '../../components/gallary/Gallary';
import Header from '../../components/header/Header';
import HeroArea from '../../components/heroArea/heroArea';



const Home = () => {


    return (
        <div>
            <Header />
            <HeroArea />
            <Courses />
            <Abouts />
            <Gallary />
            <Contact />
            <Footer />
        </div>
    );
};

export default Home;