
import React, { useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import "./faq.scss"
const AddFAQ = () => {
    const [text, setText] = useState();
    const [course, setCourse] = React.useState('');

    const handleChange = (event) => {
        setCourse(event.target.value);
    };
    console.log(text)

    return (
        <div>
            <div className="add-faqheader text-center">
                <h3>Add Faq </h3>
            </div>
            <div className="add-faq-form">

                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label> FAQ Title </Form.Label>
                        <Form.Control type="text" placeholder="FAQ Title " />
                    </Form.Group>
                    <FormControl fullWidth className="mb-3">
                        <InputLabel id="demo-simple-select-label">Select Course </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={course}
                            label="Select Course "
                            onChange={handleChange}
                        >
                            <MenuItem value={1}>Office Application</MenuItem>
                            <MenuItem value={2}>Web Design</MenuItem>
                            <MenuItem value={3}>Web Development </MenuItem>
                        </Select>
                    </FormControl>
                    <Editor
                        apiKey='9n6w4hpfie5z7tiyphkfo1pvt4np969p49hfhkijlukphija'
                        onEditorChange={(newText) => setText(newText)}
                        initialValue="<p>This is the initial content of the editor.</p>"
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code  wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | ',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                    <Button className="mt-4 text-right" variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </div>

        </div>
    );
};

export default AddFAQ;