import React from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import "./privacy.scss"

const Privacy = () => {
    return (
        <>

            <Header />
            <div className="privacy-area">
                <Container>
                    <h3> Privacy Policy</h3>
                    <p>অ্যাডভান্স আইটি ইনস্টিটিউট” পরিবারের সঙ্গে যুক্ত হবার জন্য আপনাকে ধন্যবাদ। আমরা আপনার ব্যক্তিগত তথ্য ও গোপনীয়তা রক্ষার্থে আমরা অঙ্গীকারবদ্ধ। আমাদের গোপনীয়তা নীতি সম্পর্কে আপনার যদি কোনো প্রশ্ন কিংবা জিজ্ঞাসা থেকে থাকে তবে ait.sherpur@gmail.com ঠিকানায় যোগাযোগ করবেন।</p>

                    <p>আমাদের বিশ্বাস, যখনই আপনি আমাদের ওয়েবসাইট কিংবা আমাদের সার্ভার ব্যবহার করে থাকেন, আপনি আপনার তথ্য ও গোপনীয়তার ব্যাপারে আমাদের ওপর সম্পূর্ণ আস্থা রেখেই তা ব্যবহার করেন। আপনার ব্যক্তিগত তথ্য ও গোপনীয়তা আমাদের কাছে অত্যন্ত গুরুত্বপূর্ণ। আমাদের এই নীতিমালার মাধ্যমে আমরা স্পষ্ট করে আপনাকে জানাতে চাই, আমরা আসলে কীরকম তথ্য সংগ্রহ করে থাকি, কীভাবে সেসব তথ্য আমরা ব্যবহার করি এবং এসব বিষয়ে আপনার কী কী অধিকার আছে।</p>

                    <p> আশা করছি, নিম্নোক্ত নীতিমালাটি আপনি মন দিয়ে পড়বেন। কারণ এটি আপনার জন্য অত্যন্ত গুরুত্বপূর্ণ। এতে যদি এমন কোনো বিষয় থেকে থাকে যার সঙ্গে আপনি একমত নন কিংবা আপনার ঝুঁকিপূর্ণ মনে হয়। তবে তৎক্ষণাৎ আপনি আমাদের সাথে যোগাযোগ করুন। এছাড়াও আমরা অনুরোধ করবো আপনি মনোযোগ দিয়ে নিচের নীতিমালাটি পড়ুন, কারণ এর মাধ্যমে আপনারা আমাদের কর্মকান্ড সম্পর্কে একটি সুষ্পষ্ট ধারণা পাবেন।</p>

                    <p>আমরা কি কি তথ্য সংগ্রহ করে থাকি:</p>

                    <p>  আমাদের সম্পর্কে জানার জন্য, আমাদের অনলাইন ফোরাম ও বিভিন্ন কার্যক্রমে সক্রিয় অংশ নেয়ার জন্য কিংবা আমাদের সঙ্গে যোগাযোগ করার জন্য আপনি যখন ওয়েবসাইটে রেজিস্ট্রেশান করে থাকেন তখন স্বেচ্ছায় আপনার কিছু ব্যক্তিগত তথ্য আমাদের প্রদান করতে হয়। আমরা সে সকল তথ্য সংগ্রহ করে থাকি।</p>
                    <p> আমাদের ওয়েবসাইটের সঙ্গে আপনার ঘনিষ্টতা, আপনার ব্যবহৃত পণ্য ও পরিষেবার ধরনণের ওপর নির্ভর করে, আমরা আপনার থেকে কী কী তথ্য সংগ্রহ করবো। যেসব তথ্য সংগ্রহ করা হয় তাতে নিম্নোক্ত বিষয়গুলো অন্তর্ভুক্ত থাকতে পারে:</p>
                    <p>১. আপনার প্রদান করা ব্যক্তিগত তথ্য। যেমন: ফোন নম্বর, ই-মেইল এড্রেস, নাম, ঠিকানা, পাসওয়ার্ড ইত্যাদি।</p>
                    <p> ২. আর্থিক লেনদেন সম্পর্কিত তথ্য। আপনার ক্রয়কৃত পণ্য কিংবা লেনদেনের সুবিধার্থে প্রয়োজনীয় তথ্যসমূহ আমরা সংগ্রহ করে থাকি। যেমন: ক্রেডিট কার্ড নাম্বার এবং আর্থিক লেনদেনের মাধ্যমের সাথে সম্পৃক্ত পাসওয়ার্ড।</p>
                    <p>
                        ৩. সামাজিক যোগাযোগ মাধ্যম সম্পর্কিত তথ্য। আপনার সামাজিক যোগাযোগ মাধ্যম (যেমন: ফেসবুক, টুইটার বা অন্যান্য) বা সোশ্যাল মিডিয়া একাউন্টের সাহায্যে আপনি চাইলে আমাদের ওয়েবসাইটে নিবন্ধন করতে পারবেন।</p>
                    <p>আপনার সরবরাহকৃত সকল তথ্য সত্য, সম্পূর্ণ ও নির্ভুল হতে হবে এবং এসব তথ্যে কোনো পরিবর্তন হলে তা সম্পর্কে অবশ্যই আমাদের জানাতে হবে।</p>

                    <p> স্বয়ংক্রিয়ভাবে তথ্য সংগ্রহ করা:</p>

                    <p> আপনি যখন আমাদের ওয়েব পেজ ভিজিট করে থাকেন কিংবা ব্যবহার করেন তখন কিছু নির্দিষ্ট তথ্য আমরা সংগ্রহ করি। এই তথ্যগুলো আমাদেরকে আপনার পরিচয় জানতে সাহায্য করে না (যেমন: আপনার নাম, কোথায় থাকেন)। তবে আপনি কোন ডিভাইস দিয়ে সাইটে প্রবেশ করেছেন, ডিভাইসের আইপি এড্রেস কী, অপারেটিং সিস্টেম, আপনি কোন দেশে অবস্থান করছেন, ল্যাঙ্গুয়েজ প্রেফারেন্সেস ইত্যাদি সম্পর্কে জানতে সাহায্য করে। এছাড়াও আপনি কখন এবং কীভাবে আমাদের সাইটটিতে প্রবেশ করেছেন এমন বিভিন্ন তথ্য সম্পর্কেও জানা যায়।</p>

                    <p> এই তথ্যগুলো মূলত আমাদের ওয়েবসাইটের সুরক্ষা এবং পরিচালনার ক্ষেত্রে প্রয়োজন হয়। অভ্যন্তরীণ বিশ্লেষণ এবং প্রতিবেদন তৈরিতেও এই তথ্যসমূহ সাহায্য করে।</p>
                    <p>কীভাবে আমরা আপনার তথ্যসমূহ ব্যবহার করি:</p>
                    <p>আমরা আপনার সম্মতি নিয়ে এবং আইনী বাধ্যবাধকতা মেনেই আমাদের ব্যবসায়িক স্বার্থে আপনার তথ্য ব্যবহার করে থাকি।</p>

                    <p> আমরা কি কুকিজ ও অন্যান্য ট্র‍্যাকিং প্রযুক্তি ব্যবহার করি?</p>
                    <p> হ্যাঁ। কখনও কখনও আমরা তথ্য সংগ্রহ করার জন্য কুকিজ এবং অন্য ট্র‍্যাকিং প্রযুক্তি ব্যবহার করে থাকি।</p>
                    <p> আমরা কী নীতিমালা আপডেট করে থাকি?</p>
                    <p>হ্যাঁ। প্রচলিত ও প্রাসঙ্গিক আইনের যেন কোনো প্রকার লংঘন না হয় সেদিকে খেয়াল রেখেই আমরা প্রয়োজন অনুসারে নীতিমালা আপডেট করে থাকি।</p>
                    <p> আমাদের সঙ্গে এ বিষয়ে যোগাযোগ করার উপায়:</p>


                    <p> আপনার যদি এ ব্যাপারে কোনো প্রশ্ন অথবা মন্তব্য থাকে তাহলে আমাদের মেইল করতে পারেন এই ঠিকানায়:</p>
                    <p>ait.sherpur@gmail.com</p>
                    <p>সব শেষে অ্যাডভান্স আইটি ইনস্টিটিউট পরিবারের সঙ্গে যুক্ত হওয়ার জন্য আপনাকে অভিনন্দন।</p>

                    <p>Advance Training Center </p>


                </Container>
            </div>

            <Footer />

        </>
    );
};

export default Privacy;