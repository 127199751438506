import React from 'react';
import { Container } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Paper, TableRow, TableHead, TableContainer, TableBody, Table, Button } from '@mui/material';
import { DeleteOutline, Edit, Visibility } from '@mui/icons-material';
import "./faq.scss"
import { onConfirm } from 'react-confirm-pro';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const FAQ = () => {
    const onClickLight = () => {
        onConfirm({
            title: (
                <h3>
                    Are you sure?
                </h3>
            ),
            description: (
                <p>Do you really want to delete this records? This process cannot be undone.</p>
            ),
            onSubmit: () => {
                alert("Submit")
            },
            onCancel: () => {
                alert("Cancel")
            },
        })
    };
    return (
        <div>
            <Container>
                <div className="table-header text-center">
                    <h3>All Registration  User  </h3>
                </div>
                <div className="table-body">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Title </StyledTableCell>
                                    <StyledTableCell align="center">Description</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell align="center"> Kamrul Hasan </StyledTableCell>
                                    <StyledTableCell align="center">kamrulcoder@gmail.com</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button variant="contained" color="secondary" sx={{ mr: 0.5 }}><Visibility /></Button>
                                        <Button variant="contained" sx={{ mr: 0.5 }}><Edit /></Button>
                                        <Button variant="contained" color="error" onClick={onClickLight}><DeleteOutline /></Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Container>
        </div>
    );
};

export default FAQ;