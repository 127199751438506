import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import banner from "../../images/banner-bg.png"
import Header from '../header/Header';
import Footer from '../footer/Footer';
import "./SingleCourse.scss"
import AOS from "aos";

import { AccountCircle, AccessTime, PlayLesson, PeopleAlt, Language, SettingsAccessibility, Quiz, VerifiedUser, CastForEducation, Check } from '@mui/icons-material';
const SingleOffice = () => {

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <>
            <Header />
            <div className="single-course-hero">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div data-aos="fade-right" className="course-hero-left" data-aos="fade-left">
                                <h2>কম্পিউটার অফিস অ্যাপ্লিকেশন </h2>
                                <ul className="list">
                                    <li> কোর্সের মেয়াদ :   <span> ৩/৬ মাস </span></li>
                                    <li> কোর্স ফি  :   <span> ৪০০০ টাকা</span></li>
                                    <li>ক্লাসের সময় :   <span> শনি-সোম-বুধ / রবি-মঙ্গল-বৃহস্পতি / প্রতিদিন </span></li>
                                    <li> ক্লাস শুরুর তারিখ :    <span> ৭/৮ জনের ব্যাচ হওয়া সাপেক্ষে </span></li>
                                    <li>    <strong>সেশন</strong>  : <span> জানুয়ারি - জুন এবং জুলাই - ডিসেম্বর</span></li>
                                    <li>    <strong>ভর্তির যোগ্যতা:</strong>  : <span> ন্যূনতম JSC পাশ</span></li>


                                </ul>
                                <p>কম্পিউটার শিক্ষা হলো একটা প্ল্যাটফরম। জীবন গড়ে নেওয়ার জন্য যে ট্রেনেই তুমি উঠতে চাও না কেন, এই প্ল্যাটফরমে তোমায় পৌঁছতেই হবে।।
                                </p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="course-hero-right" data-aos="fade-right">
                                <img src={banner} alt="Image  not found " />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="course-details">

                <Container>
                    <Row>
                        <Col lg={8} md={6}>
                            <div className="course-details-left" >
                                <div className="course-img" data-aos="zoom-out-up">
                                    <img src="https://i.ibb.co/NS8fctP/office-application.png" alt="Image not  found " />
                                </div>

                                <div className="courses-details-desc-style-two" data-aos="zoom-out-up">
                                    <h3>সুবিধাসমূহ:   </h3>

                                    <ul className="description-features-list" data-aos="zoom-out-up">
                                        <li> মেয়েদের জন্য  রয়েছে মহিলা প্রশিক্ষক</li>
                                        <li> নিজস্ব কম্পিউটারের প্রয়োজন নেই </li>

                                        <li> প্রতি ক্লাসে মাত্র ৮-১০ জন শিক্ষার্থী </li>
                                        <li> পরিস্কার পরিচ্ছন্ন ২টি টয়লেট ও বিশুদ্ধ খাবার পানির ব্যবস্থাসহ বৃহৎ পরিসরে প্রশিক্ষণ </li>
                                        <li> স্বল্প খরচে কোর্স সমাপ্তের পরেও প্র্যাকটিস সুবিধা  </li>
                                        <li> সার্বক্ষণিক বিদ্যুৎ সুবিধা </li>
                                        <li> প্রতিটি কম্পিউটারে ইন্টারনেট সংযোগ    </li>
                                        <li> সুবিধাজনক সময়ে সপ্তাহে ৩/৬ দিন ক্লাস করার সুযোগ    </li>
                                        <li> সার্বক্ষণিক দুইজন প্রশিক্ষক      </li>
                                        <li>AC ও সিসি ক্যামেরা নিয়ন্ত্রিত ক্লাসরুম</li>
                                        <li>অনলাইন প্ল্যাটফরমে লাইভ সাপোর্ট   </li>
                                        <li>ফেসবুক গ্রুপে বিভিন্ন জটিল সমস্যার ভিডিও টিউটোরিয়াল প্রদান  </li>
                                    </ul>
                                    <h3>ভর্তির জন্য যা লাগবে: </h3>
                                    <div className="why-you-learn" data-aos="zoom-out-up">
                                        <ul>
                                            <li><span><Check /></span> স্বহস্তে পূরণকৃত ভর্তি ফরম   </li>
                                            
                                            <li><span><Check /></span> JSC/SSC এর মার্কশিটের ফটোকপি</li>
                                            <li><span><Check /></span>২ কপি পাসপোর্ট সাইজের রঙিন ছবি</li>

                                            <li><span><Check /></span> জন্ম নিবন্ধন বা জাতীয় পরিচয় পত্রের ফটোকপি (যদি থাকে) </li>

                                        </ul>
                                    </div>




                                    <h3>পরীক্ষা : </h3>
                                    <div className="why-you-learn" data-aos="zoom-out-up">
                                        <ul>
                                            <li><span><Check /></span> এডভান্সড আইটি 		 : ২৪০   </li>
                                            <li><span><Check /></span>  বোর্ড পরীক্ষা (আলাদা কেন্দ্রে) : ২৬০
                                            </li>
                                            <li><span><Check /></span> লিখিত ৬০ + প্র্যাকটিক্যাল ২০০
                                            </li>
                                            <li><span><Check /></span> সর্বমোট 			 : ৫০০
                                            </li>

                                        </ul>


                                    </div>

                                    <h3>সার্টিফিকেট : </h3>
                                    <p>বোর্ড পরীক্ষার ২ মাস পর বাংলাদেশ কারিগরি শিক্ষা বোর্ডের    <a href="http://www.bteb.gov.bd" target="_blank">ওয়েবসাইট</a> এ ফলাফল প্রকাশিত হয়। ফলাফল প্রকাশের ১ মাস পর সার্টিফিকেট প্রদান করা হয়। </p>

                                </div>

                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="courses-sidebar-information" data-aos="zoom-out-up">
                                <ul className="info">
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>  <AccountCircle />  Instructor </span>
                                            Sanjoy Banik
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span> <AccessTime />  Duration</span>3 - 6 Months
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><PlayLesson /> Lessons</span>60
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><SettingsAccessibility /> Access</span>Lifetime
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><Quiz /> Quizzes</span>YES
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><VerifiedUser /> Certificate</span>NO
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Footer />




        </>
    );
};

export default SingleOffice;