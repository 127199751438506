import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import banner from "../../images/banner-bg.png"
import Header from '../header/Header';
import Footer from '../footer/Footer';
import "./SingleCourse.scss"
import AOS from "aos";

import { AccountCircle, AccessTime, PlayLesson, PeopleAlt, Language, SettingsAccessibility, Quiz, VerifiedUser, CastForEducation, Check } from '@mui/icons-material';
const SingleCourse = () => {

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <>
            <Header />
            <div className="single-course-hero">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div data-aos="fade-right" className="course-hero-left" data-aos="fade-left">
                                <h2>ফুল স্ট্যাক জাভাস্ক্রিপ্ট - মার্ন স্ট্যাক</h2>
                                <ul className="list">
                                    <li> কোর্সের মেয়াদ :   <span> ৭ মাস</span></li>
                                    <li> কোর্স ফী :   <span>৩০,০০০ টাকা</span></li>
                                    <li>ক্লাসের সময় :   <span>রবি, মঙ্গল ও বৃহস্পতি - রাত ৮টা - ১০টা</span></li>
                                    <li> ক্লাস শুরুর তারিখ :    <span> ৪ই নভেম্বর ২০২১</span></li>
                                </ul>
                                <p>অভিজ্ঞতা অর্জন হয় দক্ষতা বৃদ্ধির মাধ্যমে। আর দক্ষতাই পারে সাফল্যের শিখরে পৌঁছে দিতে। তাই সময় নষ্ট না করি, দক্ষতা বৃদ্ধি করি।</p>
                                <div className="hero-btns">
                                    <a href="#">ভর্তি হোন</a>
                                    <a href="#">  সিলেবাস </a>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="course-hero-right" data-aos="fade-right">
                                <img src={banner} alt="Image  not found " />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="course-details">

                <Container>
                    <Row>
                        <Col lg={8} md={6}>
                            <div className="course-details-left" >
                                <div className="course-img" data-aos="zoom-out-up">
                                    <img src="https://edemy-react.envytheme.com/images/courses/courses2.jpg" alt="Image not  found " />
                                </div>

                                <div className="courses-details-desc-style-two" data-aos="zoom-out-up">
                                    <h3>Description</h3>
                                    <p><strong>Hi!Welcome to Photography Crash Course for Photographer, the only course you need to become a BI Analyst.</strong></p>
                                    <p>Here are some more details of what you get with The Business Intelligence Analyst Course: </p>
                                    <ul className="description-features-list" data-aos="zoom-out-up">
                                        <li><strong>Introduction to Data and Data Science</strong> – Make sense of terms like business intelligence, traditional and big data, traditional statistical methods, machine learning, predictive analytics, supervised learning, unsupervised learning, reinforcement learning, and many more; </li>
                                        <li><strong>Database theory</strong> – Before you start using SQL, it is highly beneficial to learn about the underlying database theory and acquire an understanding of why databases are created and how they can help us manage data; </li>
                                        <li><strong>SQL</strong> – when you can work with SQL, it means you don’t have to rely on others sending you data and executing queries for you.You can do that on your own.This allows you to be independent and dig deeper into the data to obtain the answers to questions that might improve the way your company does its business; </li>
                                    </ul>

                                    <div className="courses-curriculum" data-aos="zoom-out-up">
                                        <h3>Python Introduction</h3>
                                        <ul>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                            <li>
                                                <CastForEducation />
                                                <span>Module 01 : </span>
                                                Python Introduction
                                            </li>
                                        </ul>

                                    </div>
                                    <h3>What you'll learn</h3>
                                    <div className="why-you-learn" data-aos="zoom-out-up">
                                        <ul>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                            <li><span><Check /></span> Become an expert in Statistics</li>
                                        </ul>
                                    </div>
                                    <h3>Requirements</h3>
                                    <ul className="requirements-list" data-aos="zoom-out-up">
                                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry.</li>
                                        <li>The standard Lorem Ipsum passage, used since the 1500s.</li>
                                    </ul>
                                    <h3>Who this course is for: </h3>
                                    <ul className="audience-list" data-aos="zoom-out-up">
                                        <li>Beginners to programming and data science</li>
                                        <li>Students eager to learn about job opportunities in the field of data science</li>
                                        <li>Candidates willing to boost their resume by learning how to combine the knowledge of Statistics, SQL, and Tableau in a real-world working environment</li>
                                        <li>People interested in a Business Intelligence Analyst career</li>
                                    </ul>
                                </div>

                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="courses-sidebar-information" data-aos="zoom-out-up">
                                <ul className="info">
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>  <AccountCircle />  Instructor </span>
                                            James Anderson
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span> <AccessTime />  Duration</span>7 weeks
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><PlayLesson /> Lessons</span>25
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span> <PeopleAlt /> Enrolled</span>255 students
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><Language /> Language</span>English
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><SettingsAccessibility /> Access</span>Lifetime
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><Quiz /> Quizzes</span>YES
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><VerifiedUser /> Certificate</span>YES
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn-box">
                                    <a className="default-btn" href="#"><i className="flaticon-shopping-cart"></i> Add to Cart <span></span></a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Footer />




        </>
    );
};

export default SingleCourse;