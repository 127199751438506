/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Navbar.css";
import avatar from "../../assets/avatar.svg";
import { Menu, Preview } from '@mui/icons-material';
import { Dropdown, Image, Button } from "react-bootstrap"
import { NavLink } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const Navbar = ({ sidebarOpen, openSidebar }) => {
  const { logout } = useAuth()

  return (
    <nav className="navbar">
      <div className="nav_icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars" aria-hidden="true"></i>
        <Menu />
      </div>
      <div className="navbar__left" >

        <a className="active_link" href="#">
          Admin
        </a>
      </div>
      <div className="navbar__right">
        <NavLink as={Button} to="/" variant="success" sx={{ mx: 2 }} >View <Preview /></NavLink>
        <Button variant="contained" onClick={() => logout()} sx={{ mx: 2 }}>Logout</Button>
      </div>
    </nav>
  );
};

export default Navbar;