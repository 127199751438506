import React, { useState, useEffect } from 'react';
import { LightBox } from 'react-lightbox-pack';
import "react-lightbox-pack/dist/index.css";
import data from './data.json';
import "./gallary.css"
import AOS from "aos";

const Gallary = () => {
    const [toggle, setToggle] = React.useState(false);
    const [sIndex, setSIndex] = React.useState(0);

    // Handler
    const lightBoxHandler = (state, sIndex) => {
        setToggle(state);
        setSIndex(sIndex);
    };

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <>

            <section className="gallery-area" id="gallary">
                <div className="container">
                    <div data-aos="zoom-in-up" className="upper-content text-center">
                        <h2>গ্যালারি </h2>

                    </div>
                    <div className="gallery-main">
                        <div className="row">
                            {data.map((item, index) => (
                                <div key={index} className="col-md-6 col-lg-4">
                                    <div className="gallery-item" data-aos="zoom-in">

                                        <img
                                            key={item.id}
                                            src={item.image}
                                            alt={item.title}

                                            onClick={() => {
                                                lightBoxHandler(true, index);
                                            }}
                                        />

                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>


            <LightBox
                state={toggle}
                event={lightBoxHandler}
                data={data}
                imageWidth="60vw"
                imageHeight="70vh"
                thumbnailHeight={50}
                thumbnailWidth={50}
                setImageIndex={setSIndex}
                imageIndex={sIndex}
            >
            </LightBox>
        </>
    );
};

export default Gallary;