import React, { useState, useEffect } from 'react';
import "./footer.css"
import logo from "../../images/logo.png"
import AOS from "aos";
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';

const Footer = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <>
            <footer className="footer-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 order-md-1">
                            <div className="footer-item" data-aos="fade-right"
                            >
                                <a href="index.html">
                                    <img className="footer-logo" src={logo} alt="Image not found " />
                                </a>

                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 order-md-3 order-lg-2">
                            <div className="footer-item ps-xl-5" data-aos="fade-up"
                            >
                                <h4>About Us</h4>
                                <ul>
                                    <li><NavLink to="/terms">Terms and
                                        Conditions</NavLink></li>
                                    <li><NavLink to="/privacy"> Privacy Policy</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 order-md-4 order-lg-3">
                            <div className="footer-item" data-aos="fade-up"
                            >
                                <h4>Follow Us</h4>
                                <ul className="social-icon">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-skype"></i></a></li>
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 order-md-2 order-lg-4">
                            <div className="footer-item" data-aos="fade-left"
                            >
                                <img src="https://monirwd.github.io/single_page/images/payment-method.png" alt="Image not found " />
                            </div>
                        </div>
                    </div>
                    <div className="copyright-part" data-aos="fade-up"
                    >
                        <p>Copyright © 2021 All right reserved ait.edu.bd</p>
                    </div>
                </div>
            </footer>
            <ToastContainer />


        </>
    );
};

export default Footer;