import React from 'react';
import { Container } from 'react-bootstrap';
import thanksImg from "../../images/check.png"
import "./thanks.scss"
import Header from "../../components/header/Header"
import Footer from "../../components/footer/Footer"

const Thanks = () => {
    return (
        <>
            <Header />
            <div className="thanks-page">
                <Container>
                    <div className="thanks-content">

                        <div className="thanks">
                            <h2>Thank You !</h2>
                            <h4>রেজিস্ট্রেশন করার জন্য আপনাকে অসংখ্য ধন্যবাদ।  আপনার সাথে আমরা যেকোনো আপডেট খবর দেওয়ার জন্য যোগাযোগ করবো।  </h4>
                            <div className="img">
                                <img src={thanksImg} alt="Image not found " />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <Footer />

        </>
    );
};

export default Thanks;