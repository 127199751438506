import React from 'react';
import { HashLink as Link } from "react-router-hash-link";

const Nav = () => {
    return (
        <ul className="navbar-nav ms-auto" id="main-nav">
            <li><Link smooth to="/#hero" className="nav-link .section1">Home</Link></li>
            <li><Link smooth to="/#courses" className="nav-link">Course</Link></li>
            <li><Link smooth to="/#about" className="nav-link">About </Link></li>
            <li><Link smooth to="/#gallary" className="nav-link">Gallery</Link></li>
            <li><Link smooth to="/#contact" className="nav-link">Contact</Link></li>
        </ul>
    );
};

export default Nav;