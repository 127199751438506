
import './App.css';
import Main from "./backend/components/main/Main"
import 'react-toastify/dist/ReactToastify.css';


import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Dashboard from './backend/Pages/Dashboard/Dashboard';
import AllUser from './backend/Pages/Users/AllUser';
import AddUser from './backend/Pages/Users/AddUser';
import FAQ from './backend/Pages/FAQ/FAQ';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddFAQ from './backend/Pages/FAQ/AddFAQ';
import Home from './frontend/pages/Home/Home';
import Registration from './frontend/pages/Registration/Registration';
import LoginIndex from './extra/Login/LoginIndex';
import Login from './extra/Login/Login';
import SignUp from './extra/Login/SignUp';
import Forgot from './extra/Login/Forgot';
import "aos/dist/aos.css";
import SingleCourse from './frontend/components/courses/SingleCourse';
import Privacy from './frontend/pages/Privacy/Privacy';
import TermAndCondition from './frontend/pages/termAndCondition.js/TermAndCondition';
import AllContacts from './backend/Pages/Users/AllContacts';
import PrivateRoute from "./extra/PrivateRoute/PrivateRoute";
import AdminRoute from "./extra/PrivateRoute/AdminRoute";
import useAuth from './hooks/useAuth';
import Thanks from "./frontend/pages/thanks/Thanks"
import SingleOffice from "./frontend/components/courses/SingleOffice"
import SingleIct from "./frontend/components/courses/SingleIct"
import SingleWebDesign from "./frontend/components/courses/SingleWebDesign"
import SingleWebDevelopment from "./frontend/components/courses/SingleWebDevelopment"
function App() {

  const { user, registered } = useAuth();
  return (

    <div className="App">
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/thanks" element={<Thanks />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<TermAndCondition />} />

          <Route path="/single-course" element={<SingleCourse />} />
          <Route path="/computer-office-application" element={<SingleOffice />} />
          <Route path="/hsc-ict" element={<SingleIct />} />
          <Route path="/web-deign" element={<SingleWebDesign />} />
          <Route path="/web-development" element={<SingleWebDevelopment />} />



          {/* <Route path="/reg" element={<PrivateRoute> {registered ? <Thanks /> : <Registration />}</PrivateRoute>} /> */}
          <Route path="/dashboard" element={<AdminRoute><Dashboard /></AdminRoute>} >
            <Route index element={<Main />} />
            <Route path="all-user" element={<AllUser />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="user-contacts" element={<AllContacts />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="add-faq" element={<AddFAQ />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
