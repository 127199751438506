import React, { useState } from 'react';
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import {
    Outlet
} from "react-router-dom";
const Dashboard = () => {
    const [sidebarOpen, setsidebarOpen] = useState(false);
    const openSidebar = () => {
        setsidebarOpen(true);
    };
    const closeSidebar = () => {
        setsidebarOpen(false);
    };
    return (
        <div className="App" >
            <div className="dashbaord-container ">

                <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
                <div className="main-dashboard">
                    <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
                    <Outlet />
                </div>


            </div>
        </div>
    );
};

export default Dashboard;