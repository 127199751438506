/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Sidebar.css";
import logo from "../../assets/logo.png";
import CloseIcon from '@mui/icons-material/Close';
import { Menu, PersonAdd, PeopleAlt, Dashboard, LiveHelp } from '@mui/icons-material';
import { NavLink } from "react-router-dom";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  return (
    <div className={sidebarOpen ? "sidebar_responsive" : ""} id="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebar__title">
          <div className="sidebar__img">
            <img src={logo} alt="logo" />
            <h1>AIT ADMIN</h1>
          </div>
          <Menu className="closeNav " sx={{ display: { xs: 'block', lg: 'none' } }} onClick={() => closeSidebar()} />

        </div>

        <div className="sidebar__menu">
          <div className="sidebar__link active_menu_link">
            <NavLink to="/dashboard" >
              <Dashboard />
              <span>Dashboard</span>
            </NavLink>
          </div>
          <h2>User Manage</h2>
          <div className="sidebar__link">
            <NavLink to="/dashboard/all-user" >
              <PeopleAlt />
              <span>All Registration </span>
            </NavLink>
          </div>
          <div className="sidebar__link">
            <NavLink to="/dashboard/add-user" >
              <PersonAdd />
              <span>Add User </span>
            </NavLink>
          </div>
          <div className="sidebar__link">
            <NavLink to="/dashboard/user-contacts" >
              <PersonAdd />
              <span>User Contacts  </span>
            </NavLink>
          </div>


          <h2>FAQ</h2>
          <div className="sidebar__link">
            <NavLink to="/dashboard/faq" >
              <LiveHelp />
              <span>All FAQ</span>
            </NavLink>

          </div>
          <div className="sidebar__link">
            <NavLink to="/dashboard/add-faq" >
              <LiveHelp />
              <span>Add FAQ</span>
            </NavLink>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Sidebar;