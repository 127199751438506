import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import "./heroArea.css"
import "./hero.scss"
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom';

import heroImg1 from "../../images/hero-1.png"
import heroImg2 from "../../images/hero-2.png"
import heroImg3 from "../../images/hero-3.jpg"




const HeroArea = () => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <>
            <section className="hero-area" id="hero">
                <Carousel fade activeIndex={index} onSelect={handleSelect}>
                    <Carousel.Item className="p-0">
                        <div className="slider1  py-5">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 order-2 order-lg-1">
                                        <div className="hero-left" data-aos="fade-right">
                                            <h2>প্রযুক্তির হাত ধরে এগিয়ে যাওয়ার প্রত্যয়ে <br />  <span> এডভান্সড আইটি ইনস্টিটিউট রয়েছে তোমার পাশে। </span></h2>
                                            <p>পছন্দ অনুযায়ী অনলাইন কোর্স, ট্রেনিং আর ক্যারিয়ার ট্র্যাক প্রোগ্রামে ভর্তি হয়ে বর্তমান সময়ের সবচেয়ে গুরুত্বপূর্ণ দক্ষতাগুলো অর্জন করো</p>
                                            <a target="_blank" href="https://forms.gle/TLiKTum83vPNCDng7">Registration</a>
                                           

                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="hero-right" data-aos="fade-left">
                                            <img src={heroImg1} alt="Image not  found " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="p-0">
                        <div className="slider2  py-5">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 order-2 order-lg-1">
                                        <div className="hero-left" data-aos="fade-right">
                                            <h2>এডভান্সড  আইটি   এর    <span> সাথে  জীবন পরিবর্তন কর</span></h2>
                                            <p>এডভান্সড  আইটি  তোমাকে  ভবিষ্যতের জন্য দক্ষ করে গড়ে তোলে এবং ক্যারিয়ার দৌঁড়ে অনেক প্রতিযোগী থেকে এগিয়ে রাখে।</p>
                                            <a target="_blank" href="https://forms.gle/TLiKTum83vPNCDng7">Registration</a>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="hero-right" data-aos="fade-left">
                                            <img src={heroImg2} alt="Image not found " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item className="p-0" >
                        <div className="slider3  py-5">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 order-2 order-lg-1">
                                        <div className="hero-left" data-aos="fade-right">
                                            <h2>এডভান্সড  আইটি   এর সাথে  <span> ক্যারিয়ার  প্রসারিত কর</span>  </h2>
                                            <p>বর্তমান সময়ের কর্মসংস্থান হলো দক্ষতাভিত্তিক। যার দক্ষতা যত বেশি, তার কাজের সুযোগও তত বেশি। ভবিষ্যতে এমনটা আরও বাড়বে। তাই এখনই দক্ষ হওয়ার কোনো বিকল্প নেই। </p>

                                            <a target="_blank" href="https://forms.gle/TLiKTum83vPNCDng7">Registration</a>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="hero-right" data-aos="fade-left">
                                            <img src={heroImg3} alt="Image not found " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </section>



        </>

    );
};

export default HeroArea;