import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Paper, TableRow, TableHead, TableContainer, TableBody, Table, Button } from '@mui/material';
import { Paid } from '@mui/icons-material';
import "./allUser.scss"
import axios from "axios";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const AllUser = () => {
    const [registers, setregisters] = useState([])

    useEffect(() => {
        const fetchPosts = async () => {
            const res = await axios.get("/registers");
            setregisters(res.data);
        };
        fetchPosts();
    }, [registers]);

    return (
        <div>
            <Container>
                <div className="table-header text-center">
                    <h3>All Registration  User  </h3>
                </div>
                <div className="table-body">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Name</StyledTableCell>
                                    <StyledTableCell align="center">Email Address</StyledTableCell>
                                    <StyledTableCell align="center">Phone</StyledTableCell>
                                    <StyledTableCell align="center">Payment Status</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {registers.map(register => (
                                    <StyledTableRow key={register._id}>
                                        <StyledTableCell align="center">{register.name} </StyledTableCell>
                                        <StyledTableCell align="center">{register.email}</StyledTableCell>
                                        <StyledTableCell align="center">{register.phone}</StyledTableCell>
                                        <StyledTableCell align="center"><Button disabled variant="contained"><Paid /></Button></StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Container>
        </div>
    );
};

export default AllUser;