import React, { useState } from 'react';

import { NavHashLink as Link } from "react-router-hash-link";

import "./header.css"
import logo from "../../images/logo.png"

import { Navbar, Offcanvas } from "react-bootstrap"
import { NavLink, useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { Avatar, Button, MenuItem } from '@material-ui/core';
import { Menu, Tooltip } from '@mui/material';
import useAuth from "../../../hooks/useAuth"
import defaultImage from "../../images/avatar.webp"

const Header = () => {
    const navigate = useNavigate();

    const { user, logout, admin } = useAuth()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDropClose = () => {
        setAnchorEl(null);
    };

    const passToDashboard = () => {
        navigate('/dashboard', { replace: true })
    }

    return (
        <>
            <header className="header-area" >

                <div className="container">
                    <nav className="navbar navbar-expand-lg p-0  border-0">
                        <Navbar.Brand as={NavLink} to="/">
                            <img as={NavLink} src={logo} alt="Image not found " />
                        </Navbar.Brand>
                        <button className="navbar-toggler" type="button" onClick={handleShow}>
                            <span className="hamburger-box" >
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        <div className="collapse navbar-collapse">
                            <Nav />
                            <ul className="navbar-nav nav-btn ms-auto">
                                <li><a href="https://forms.gle/TLiKTum83vPNCDng7" target="_blank">Registration</a></li>
                                <li>

                                    {user?.email && <div>
                                        <Tooltip title={user.displayName} arrow>
                                            <Avatar
                                                alt={user?.displayName}
                                                src={user?.photoURL || defaultImage}
                                                sx={{ width: 100, height: 100 }}

                                                id="basic-button"
                                                aria-controls="basic-menu"
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}
                                            />
                                        </Tooltip>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleDropClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            <MenuItem onClick={logout}>Logout</MenuItem>
                                            {admin ? (
                                                <MenuItem onClick={passToDashboard}>Dashboard</MenuItem>
                                            ) : ""}
                                        </Menu>
                                    </div>}
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

            </header>
            <Offcanvas show={show} onHide={handleClose}>
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                        <NavLink to="/">
                            <img src={logo} alt="Image not found " />
                        </NavLink>
                    </h5>
                    <button type="button" className="btn-close text-reset" onClick={handleClose}></button>
                </div>
                <Offcanvas.Body>
                    <Nav />
                    <ul className="navbar-nav nav-btn ms-auto">
                        <li><a target="_blank" href="https://forms.gle/TLiKTum83vPNCDng7">Registration</a></li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Header;