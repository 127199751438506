import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import banner from "../../images/banner-bg.png"
import Header from '../header/Header';
import Footer from '../footer/Footer';
import "./SingleCourse.scss"
import AOS from "aos";

import { AccountCircle, AccessTime, PlayLesson, PeopleAlt, Language, SettingsAccessibility, Quiz, VerifiedUser, CastForEducation, Check } from '@mui/icons-material';
const SingleIct = () => {

    useEffect(() => {
        AOS.init({
            offset: 100,
            duration: 1000,
            easing: "ease"
        });
    });
    return (
        <>
            <Header />
            <div className="single-course-hero">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div data-aos="fade-right" className="course-hero-left" data-aos="fade-left">
                                <h2>HSC ICT with Sanjoy Sir</h2>
                                <ul className="list">
                                    <li> কোর্সের মেয়াদ :   <span> ৩/৬ মাস </span></li>
                                    <li> কোর্স ফি  :   <span>৪০০০ টাকা</span></li>
                                    <li>ক্লাসের সময় :   <span>শনি-সোম-বুধ / রবি-মঙ্গল-বৃহস্পতি / প্রতিদিন </span></li>
                                    <li> ক্লাস শুরুর তারিখ :    <span>৫ ডিসেম্বর, ২০২১ </span></li>
                                </ul>
                                <p>প্রজেক্টরে প্রতিটি প্র্যাকটিক্যাল ক্লাস গ্রহণ এবং Android ফোনে ওয়েবপেজ তৈরি ও প্রোগ্রামিং প্র্যাকটিস।</p>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="course-hero-right" data-aos="fade-right">
                                <img src="https://i.ibb.co/nc40tfB/ict-banner2.png" alt="Image  not found " />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className="course-details">

                <Container>
                    <Row>
                        <Col lg={7} md={6}>
                            <div className="course-details-left" >
                                <div className="course-img" data-aos="zoom-out-up">
                                    <img src="https://i.ibb.co/6sZvbyC/ict.jpg" alt="Image not  found " />
                                </div>

                                <div className="courses-details-desc-style-two" data-aos="zoom-out-up">
                                    <h3>সুবিধাসমূহ:</h3>

                                    <ul className="description-features-list" data-aos="zoom-out-up">
                                        <li>নিজস্ব কম্পিউটারের প্রয়োজন নেই  </li>
                                        <li>প্রজেক্টরে প্রতিটি প্র্যাকটিক্যাল ক্লাস গ্রহণ এবং Android ফোনে ওয়েবপেজ তৈরি ও প্রোগ্রামিং প্র্যাকটিস </li>
                                        <li> সর্বোচ্চ সংখ্যক ক্লাসের মাধ্যমে পুঙ্খানুপুঙ্খভাবে প্রতিটি টপিক ফ্রেন্ডলি ও আনন্দদায়কভাবে উপস্থাপন
                                        </li>
                                        <li>সপ্তাহে ৩ দিন/ ৬ দিন ক্লাসের সুবিধা। চাইলে ২ ব্যাচেও একসাথে ক্লাস করা যাবে
                                        </li>
                                        <li>শিক্ষার্থীর করা যে কোনো প্রশ্নের তাৎক্ষণিক উত্তর প্রদান </li>
                                        <li>প্রতিটি ক্লাসের পর কুইজ প্রতিযোগিতা </li>
                                        <li> অধ্যায়ভিত্তিক জ্ঞান ও অনুধাবনমূলক প্রশ্নোত্তর সংবলিত মানসম্মত শীট প্রদান</li>
                                        <li> প্রতিটি অধ্যায় শেষে বিগত সালের সকল বোর্ড পরীক্ষার সৃজনশীল ও MCQ প্রশ্ন সমাধান
                                        </li>
                                        <li>বোর্ডের অনুকরণে OMR ও MCQ Sheet দ্বারা অধ্যায়ভিত্তিক পরীক্ষা গ্রহণ ৮টি (৩০/৪০ নম্বরের) এবং ২ টি পূর্ণাঙ্গ মডেল টেস্ট (৭৫ নম্বরের </li>
                                        <li> ১০টি পরীক্ষা শেষে ১০ টি সলভ ক্লাস। সলভ ক্লাসে প্রতিটি প্রশ্নের উত্তর কিভাবে লিখলে সম্পূর্ণ মার্কস পাওয়া যাবে তা নিশ্চিত করা
                                        </li>
                                        <li>বিগত সালের সকল পাবলিক ও গুচ্ছ বিশ্ববিদ্যালয় ভর্তি পরীক্ষায় আসা ICT প্রশ্নের পূর্ণাঙ্গ সমাধান </li>
                                        <li>Practical খাতা তৈরিতে সার্বিক সহযোগিতা প্রদান
                                        </li>

                                    </ul>
                                    <h3>অন্যান্য বৈশিষ্ট্যসমূহ:
                                    </h3>
                                    <div className="why-you-learn" data-aos="zoom-out-up">
                                        <ul>
                                            <li><span><Check /></span> দীর্ঘ ৭ বছরের অভিজ্ঞতা
                                            </li>
                                            <li><span><Check /></span>  এ পর্যন্ত ৫০০০+ শিক্ষার্থীকে পাঠদান
                                            </li>
                                            <li><span><Check /></span> সম্পূর্ণ প্রতিষ্ঠান সিসি ক্যামেরা দ্বারা নিয়ন্ত্রিত
                                            </li>
                                            <li><span><Check /></span> AC রুমে ক্লাসের সুবিধা
                                            </li>
                                            <li><span><Check /></span>  বিশুদ্ধ খাবার পানির ব্যবস্থা
                                            </li>
                                            <li><span><Check /></span> পরিস্কার পরিচ্ছন্ন টয়লেটের (২টি) ব্যবস্থা
                                            </li>

                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className="courses-sidebar-information" data-aos="zoom-out-up">
                                <ul className="info">
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span>  <AccountCircle />  Instructor </span>
                                            Sanjoy Banik
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span> <AccessTime />  Duration</span>3 - 6 Months
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><PlayLesson /> Lessons</span>60
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><SettingsAccessibility /> Access</span>Lifetime
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><Quiz /> Quizzes</span>YES
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span><VerifiedUser /> Certificate</span>NO
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Footer />




        </>
    );
};

export default SingleIct;